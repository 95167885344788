.valuation-map-container {
    height: 280px;
    width: 280px;
    margin: auto;
}

.valuation-container-inner {
    background-color: #f8f8f8;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
}

.valuation-container-price {
    font-weight: bold;
    padding-top: 8px;
    -webkit-box-align: baseline;
    align-items: baseline;
    display: flex;
}


.valuation-container-price .value.low {
    color: #21ba45;
}

.valuation-container-price .value.middlelow {
    color: #20b2aa;
}

.valuation-container-price .value.middle {
    color: #ffc100;
}

.valuation-container-price .value.middlehigh {
    color: #ff7400;
}

.valuation-container-price .value.high {
    color: #ff0000;
}

.valuation-container-price .value.dscr {
    color: teal;
}

@media (min-width: 321px) {
    .valuation-container-price {
        font-size: 18px;
    }

    .valuation-container-price .value {
        font-size: 22px;
    }
}

.comp-inline-field.comp-form-field > label {
    width: 50% !important;
    text-align: left;
}

.valuation-value-container {
    display: flex;
    flex-direction: column;
    padding: 8px !important;
}

.valuation-value-label {
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
}

.valuation-value-container > .valuation-value-label:last-child {
    margin-top: 2px !important;
}

.values-container > .valuation-value-container {
    width: 50% !important;
}

.flip-flip-values-container > .valuation-value-container {
    width: 20% !important;
}

.valuation-value-container > .valuation-value-label.ui.red.label {
    background-color: inherit !important;
    border-color: inherit !important;
    color: #db2828!important;
}