@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');


html, body {
  height: inherit !important;
  font-size: 13px;
  /* font-family: 'Work Sans'; */
  font-family: 'Inter', 'Arial' !important;
}

/* @media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}  */

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.ui.menu a {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-h {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.transparent {
  background: transparent !important;
}

.buffer {
  border-bottom: 2px dashed #cacaca;
  flex: 1 1 0%;
  margin: 0px 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-me {
  display: none !important;
}

.disabled {
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}

/* .ui.modal:not(.compact) {
  width: 50% !important;
  margin-left: 25% !important;
  height: 70%!important;
  margin-top: 5% !important;
  } */

.navigation-lightblue {
  background: rgb(106, 174, 219) !important;
}

.ui.menu.navigation {
  z-index: 999999;
}

.navigation-grey {
  background: #f2f2f2 !important;
}

.lightblue {
  background: lightblue !important;
}

.lightred {
  color: #FF6161 !important;
}

.lightyellow {
  color: #E8E818 !important;
}

.color-dob, .color-complaints {
  background: #ddebf7 !important;
}

.color-oath {
  background: #fce4d6 !important;
}

.color-hpd {
  background: #fff2cc !important;
}

i.bigger.icon {
  font-size: 2.5em;
  line-height: 1;
  vertical-align: middle;
}

.ui.segments.basic {
  background: none transparent;
  border: none;
  box-shadow: none;
}

.ui.horizontal.segments.basic > .ui.segment.basic {
  border: none
}

.mapboxgl-marker {
  cursor: pointer;
}

.mapboxgl-popup-content {
  transform:none !important;
  max-width: 1300px; 
  border-radius: 8px; 
  padding: 4px 8px; 
  box-shadow: 0 3px 14px rgba(0,0,0,0.4); 
  min-width: 50px;
  max-height: 800px;
}

[id^="marker-"] > svg {
  width: 24px;
  height: 24px;
}

.ui.mini.selection.dropdown {
  font-size: .78571429em;
}

.resp-container {
  margin-top: -40px;
}

@media (min-width: 1750px) {
  .resp-container {
    margin-left: 16rem;
    margin-right: 16rem;  
  }
}

@media (min-width: 1500px) and (max-width: 1750px) {
  .resp-container {
    margin-left: 6rem;
    margin-right: 6rem;  
  }
}