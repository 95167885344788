.ui[class*="very wide"].left.sidebar.comparables-side-panel-container , .ui[class*="very wide"].right.sidebar.comparables-side-panel-container  {
    width: 50vw !important;
}

.appraisals-map-container {
    height: 280px;
    width: 100%;
    margin: auto;
}

.comparables-table > thead tr, .comparables-table > tbody tr { display: flex; }
 
.comparables-table > thead, .comparables-table > tbody { display: block; }

.comparables-table > tbody td:first-child, .comparables-table > thead th:first-child {
    background-color: white !important;
    width: 150px;
    font-size: 13px !important;
}

.comparables-table > thead th {
    font-size: 12px;
}

.comparables-table > thead th:not(:first-child),
.comparables-table > tbody td:not(:first-child) {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
}

.comparables-table > tbody tr:not(:nth-child(3)) {
    max-height: 64px;
}

.comparables-table > tbody td:nth-child(2), .comparables-table > thead th:nth-child(2) {
    background-color: rgb(247, 247, 247) !important;
}

.comparables-table > tbody {
    height: 85vh;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
}

.comparables-table tbody td.comparables-parties {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    font-size: 11px;
    font-weight: 600; 
}

.comp-inline-field {
    width: 100%;
    display: flex;
    height: 35px;
}

.comp-inline-field:not(:first-child) {
    margin-top: 1em;
}

.comp-inline-field > label {
    white-space: nowrap;
    margin-top: 8px !important;
    font-size: 11px;
    margin-right: 1em;
}

.comp-inline-field > .ui.input, .comp-inline-field > .ui.input > .ui.input,
.comp-inline-field > .ui.search, .comp-inline-field > .ui.search > .ui.input {
    width: 100% !important;
}

.comp-inline-field > .ui.search {    
    flex: 2;
}

.comp-inline-field > .ui.search {
    margin-top: 3px;
}

.comp-inline-field > .ui.input > .ui.input > input {
    margin: 0 !important;
}

.comp-inline-field > .ui.disabled.input {    
    opacity: 100 !important;
    flex: 2;
}

.comp-inline-field > label.comp-inline-price {
    text-align: center;
    flex: 1;
    line-height: 1.25em;
    white-space: pre;
    margin-top: 3px !important;
    margin-left: 6px !important;
}

input[type=date] {
    padding: 0.5em !important;
}

.comp-value-label {
    padding: 0 !important;
    color: white !important;
    border: none !important;
    background: transparent !important;
}

.comp-value-container.disabled {
    opacity: 50%;
}

.comp-inline-field > .ui.search.invalid-unit > .ui.input > input {
    color: #db2828;
    font-weight: 800;
}

.value-badge {
    width: 90px;
}